//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OrgPicker from "@/page/admin/approval-process/component/common/OrgPicker.vue";
import { delegateTask } from "@/lib/data-service/haolv-default/design";

export default {
  components: { OrgPicker,},
  name: "DelegateModal",
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false,
    },
    processInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      formValue: {
        comments: "",
        delegateUserInfo: null,
        imageList: [],
        fileList: [],
      },
      rules: {
        delegateUserInfo: [
          { required: true, message: "请选择委派人", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      },
    },
    // 用户名展示
    delegateUserInfoUserName() {
      return this.formValue.delegateUserInfo?.name || "";
    },
  },
  methods: {
    // 确认操作
    handleConfirm() {
      this.$refs.formRef.validate((valid) => {
        if (!valid) return;
        const { imageList, fileList, ...restParams } = this.formValue;
        const params = {
          ...this.processInfo,
          ...restParams,
            phone: restParams.delegateUserInfo.phone,
          attachments: [...imageList, ...fileList],
        };
        this.loading = true;
        delegateTask(params)
          .then(() => {
            this.$message.success("委派成功");
            this.handleCancel();
            this.$emit("success");
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    // 取消操作
    handleCancel() {
      this.$refs.formRef.resetFields();
      this.formValue = {
        comments: "",
        delegateUserInfo: null,
        imageList: [],
        fileList: [],
      };
      this.show = false;
        this.$emit("cancel");
    },
    // 显示用户选择器
    handleShowOrgPicker() {
      this.$refs.orgPicker.show();
    },
    // 选择用户确认操作
    handleSelectedOrg(selected) {
      if (!selected.length) {
        this.formValue.delegateUserInfo = null;
        return;
      }
      const [item] = selected;
      if (item.id === this.processInfo.currentUserInfo.id) {
        this.$message.error("不能委派给自己");
        return;
      }
      this.formValue.delegateUserInfo = item;
    },
  },
};
