//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { rollback, getRollbackNodes } from "@/lib/data-service/haolv-default/design";


export default {
  name: "RollbackModal",
  components: { },
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false,
    },
    processInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      formValue: {
        rollbackId: "",
        comments: "",
        imageList: [],
        fileList: [],
      },
      options: [],
      rules: {
        rollbackId: [
          {
            required: true,
            message: "请选择要回退到的节点",
            trigger: "change",
          },
        ],
          comments: [
              {
                  required: true,
                  message: "请填写驳回理由",
                  trigger: "blur",
              },
          ],
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      },
    },
  },
  methods: {
    // 确认操作
    handleConfirm() {
      this.$refs.formRef.validate((valid) => {
        if (!valid) return;
        const { imageList, fileList, ...restParams } = this.formValue;
        const params = {
          ...this.processInfo,
          ...restParams,
          attachments: [...imageList, ...fileList],
        };
        this.loading = true;
        rollback(params)
          .then(() => {
            this.$message.success("操作成功");
            this.handleCancel();
            this.$emit("success");
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    // 取消操作
    handleCancel() {
      this.$refs.formRef.resetFields();
      this.formValue = {
        rollbackId: "",
        comments: "",
        imageList: [],
        fileList: [],
      };
      this.options = [];
      this.show = false;
    },
    // 打开弹框操作
    handleOpen() {
      const { processInstanceId, taskId } = this.processInfo;
      getRollbackNodes({ processInstanceId, taskId }).then((res) => {
        const nodes = res.result || {};
        let options = [];
          Object.keys(nodes).forEach(key => {
              if (parseInt(this.$route.query.approveType) !== 1 && nodes[key] === '发起人') {
                  return;
              }
              options.push({
                  label: nodes[key],
                  value: key,
              })
          })
          console.log(options);
          this.options = options;
        /*this.options = Object.keys(nodes).map((key) => {
            // 如果不是出差类型，则不返回发起人
            console.log(parseInt(this.$route.query.approveType) !== 1,nodes[key] === '发起人' );
            if (parseInt(this.$route.query.approveType) !== 1 && nodes[key] === '发起人') {
                return false;
            }
            console.log({
                label: nodes[key],
                value: key,
            })
          return {
            label: nodes[key],
            value: key,
          };
        });*/
      });
    },
  },
};
