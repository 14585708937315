//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { deleteMulti, queryMultiUsersInfo } from "@/lib/data-service/haolv-default/design";


export default {
  name: "DeleteMultiModal",
  components: { },
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false,
    },
    processInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      formValue: {
        executionIds: [],
        imageList: [],
        fileList: [],
      },
      rules: {
        executionIds: [
          { required: true, message: "请选择减签用户", trigger: "change" },
        ],
      },
      options: [],
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      },
    },
  },
  methods: {
    // 确认操作
    handleConfirm() {
      this.$refs.formRef.validate((valid) => {
        if (!valid) return;
        const { imageList, fileList, ...restParams } = this.formValue;
        const params = {
          ...this.processInfo,
          ...restParams,
          attachments: [...imageList, ...fileList],
        };
        this.loading = true;
        deleteMulti(params)
          .then(() => {
            this.$message.success("操作成功");
            this.handleCancel();
            this.$emit("success");
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    // 取消操作
    handleCancel() {
      this.options = [];
      this.$refs.formRef.resetFields();
      this.formValue = {
        executionIds: [],
        imageList: [],
        fileList: [],
      };
      this.show = false;
    },
    // 打开弹框操作
    handleOpen() {
      const { processInstanceId, taskId } = this.processInfo;
      queryMultiUsersInfo({ processInstanceId, taskId }).then((res) => {
        this.options = (res.result || []).map((item) => {
          return {
            label: item.userName || item.userId,
            value: item.executionId,
          };
        });
      });
    },
  },
};
