//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { revoke } from '@/lib/data-service/haolv-default/design';

export default {
  name: 'RevokeModal',
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false
    },
    processInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      formValue: {
        comments: ''
      },
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(visible) {
        this.$emit('update:visible', visible)
      }
    }
  },
  methods: {
    // 确认操作
    handleConfirm() {
      this.$refs.formRef.validate(valid => {
        if(!valid) return;
        const params = {
          ...this.processInfo,
          ...this.formValue,
        }
        this.loading = true
        revoke(params).then(() => {
          this.$message.success('操作成功');
          this.handleCancel();
          this.$emit('success')
        }).finally(() => {
          this.loading = false
        })
      })
    },
    // 取消操作
    handleCancel() {
      this.$refs.formRef.resetFields();
      this.show = false;
    }
  }
}
