const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 接口文档地址：
// xxx
const consumer_flight_search_citys = (pParameter) => {
    if (!pParameter) pParameter = {};

    const p = {
        method: 'post',
        urlSuffix: '/consumer/flight/search/citys',
        data: {},
        check_token: false,
    };
    return Q.when()
        .then(function () {
            return __request(p);
        })
        .then(function (data) {
            const citys = data.citys;
            if (!_.isArray(citys)) {
                const msg = `consumer_flight_search_citys parameter error, code=001`;
                console.error(msg);
                console.trace();
                return Q.reject({msg: msg});
            }
            _.each(citys, function (m) {
                m.name_custom_001 = `${m.name}(${m.code})`;
            });

            return Q.resolve(data);
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};
module.exports = consumer_flight_search_citys;
