export default {
    data() {
        return {}
    },
    props: {
        row: {
            type: Array,
            dafault: () => []
        },
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {}
}