import moment from "moment";
import FlowNodeFormat from "@/component/workflow-process-line-for-array/component/flow-node-format/index.vue";
export default {
    name: 'WorkflowProcessLineForArray',
    data() {
        return {
            line: [],
        }
    },
    props: {},
    components: {
        FlowNodeFormat,
    },
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        /**
         * 初始化方法
         * 入参 obj
         * {
         *     line: array, 传入的流数组,
         * }
         * **/
        init(params) {
            let line = params.line;
            let result = [];
            line.forEach((value, index)=>{
                if (value.showTimeStr && value.showTimeStr !== 'null') {
                    value.showTimeStr = moment(value.showTimeStr).format('yyyy-MM-DD HH:mm:ss');
                } else {
                    value.showTimeStr = '';
                }
                // value.status = value.status || 1;
                /*if (value.id === 'root') {
                    value.lType = 'primary';
                    value.icon = 'el-icon-more';
                } else if (value.status === 5) {
                    value.icon = 'el-icon-check'; // el-icon-more
                    value.color = '#0bbd87';
                } else {
                    value.icon = 'el-icon-more'; // el-icon-more
                    value.lType = 'primary';
                }*/
                result.push(value);
                /*if (value.type === 'CONDITION') {
                    // 条件
                    let lastResult = result[result.length - 1];
                    if (lastResult.type === 'CONDITION-BOX') {
                        lastResult.children.push(value);
                    } else {
                        result.push({
                            name: '条件分支',
                            type: 'CONDITION-BOX',
                            children: [value],
                            id: 'node',
                        })
                    }
                } else {
                    // 普通审批节点
                    result.push(value);
                }*/


            })
            let lastStatus = -1; // -1未完成， 0 完成
            if (result.length > 0) {
                let lastNodeStatus = result[result.length - 1]['status'];
                lastStatus = [5].indexOf(lastNodeStatus) > -1 ? 0 : -1;
            }


            result.push({
                id: 'node',
                'name': '审批结束',
                status: lastStatus, // -1未完成， 0 完成
                userVoList: [],
            })
            console.log('1',result);
            this.line = result;
        },
    }
}